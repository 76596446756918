import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoFacebookButton = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 30 30" {...props}>
    <g clipPath="url(#clip0_1726_13263)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15.0838C0 22.5413 5.41625 28.7425 12.5 30V19.1663H8.75V15H12.5V11.6663C12.5 7.91625 14.9163 5.83375 18.3337 5.83375C19.4163 5.83375 20.5837 6 21.6663 6.16625V10H19.75C17.9163 10 17.5 10.9163 17.5 12.0837V15H21.5L20.8337 19.1663H17.5V30C24.5837 28.7425 30 22.5425 30 15.0838C30 6.7875 23.25 0 15 0C6.75 0 0 6.7875 0 15.0838Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1726_13263">
        <rect width={30} height={30} />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoFacebookButton);
export default Memo;
